import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { urlsPrivate, urlsPublic } from '../../environments/url-services';
@Injectable({
   providedIn: 'root',
})
export class ConsultServicesService {
   @Output() dataLoaded = new EventEmitter<any>();
   dataLevelService: any;

   setdata = (data: any) => (this.dataLevelService = data);
   getdata = () => this.dataLevelService;
   deletedata = () => (this.dataLevelService = undefined);
   getConsultType = (item: any, nameService: string, type: string): Observable<any> =>
      this.http.get<any>(`${this.url}${urlsPublic[nameService]}${type}`, { params: item });

   url: string = environment.server_api_url;
   constructor(private http: HttpClient) {}

   getAll = (nameService: string): Observable<any> => this.http.get<any>(`${this.url}${urlsPublic[nameService]}`);

   getIdType(id: number, type: string, nameService: string): Observable<any> {
      return this.http.get<any>(`${this.url}${urlsPublic[nameService]}${id}/${type}`);
   }
   getIdsType(id: number, service: number, type: string, nameService: string): Observable<any> {
      return this.http.get<any>(`${this.url}${urlsPublic[nameService]}${id}/${service}/${type}`);
   }

   patchIdTypeRequest(item: any, id: number, type: string, nameService: string): Observable<any> {
      return this.http.patch<any>(`${this.url}${urlsPublic[nameService]}${id}/${type}`, item);
   }
   patchIdType(id: number, type: string, nameService: string): Observable<any> {
      return this.http.patch<any>(`${this.url}${urlsPublic[nameService]}${id}/${type}`, {});
   }
   getConsult = (item: any, nameService: string): Observable<any> => this.http.get<any>(`${this.url}${urlsPublic[nameService]}`, { params: item });
   getConsultDetail = (nameService: string): Observable<any> => this.http.get<any>(`${this.url}${nameService}`);

   get_localstorage = (name: string) => JSON.parse(localStorage.getItem(name));
   add_localstorage = (name: string, items: any) => localStorage.setItem(name, JSON.stringify(items));
   delete_localstorage = (name: string) => localStorage.removeItem(name);
   getOne = (id: number, nameService: string): Observable<any> => this.http.get<any>(`${this.url}${urlsPublic[nameService]}${id}`);
   getOnePrivate = (id: number, nameService: string): Observable<any> => this.http.get<any>(`${this.url}${urlsPrivate[nameService]}${id}`);
}
