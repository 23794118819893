<div class="container p-5">
    <div class="row">
       <div class="col text-end p-0 m-0">
        <button type="button" class="btn close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true" class="sigmi-icon sigmi-icon-medium sigmi-close"></span>
         </button>
       </div>
    </div>
    <div class="row">
        <div class="col">
           <div class="container p-0">
              <app-sigmi-table [_modelTable]="modelTable" (eventEmitter)="receiveEvent($event)"> </app-sigmi-table>
           </div>
        </div>
    </div>

</div>