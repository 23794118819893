import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as fromModels from '../../../shared/models';
@Component({
   selector: 'app-modal-values',
   templateUrl: './modal-values.component.html',
   styleUrls: ['./modal-values.component.scss'],
})
export class ModalValuesComponent implements OnInit {
   @Input() datosTabla: any[];
   @Input() urlParams: any;
   @Input() urlDirect: boolean;
   @Input() oidFlag: boolean = false;
   @Input() dataElement: string = "";
   modelTable: fromModels.ModelTable;
   constructor(public activeModal: NgbActiveModal, private _router: Router) { }

   ngOnInit(): void {

      if (this.urlParams) {
         this.loadTable(this.datosTabla);
      } else {
         this.loadTablePublic(this.datosTabla)
      }

   }
   receiveEvent({ data, key }: fromModels.dataEmitter) {
      if (key) {
         if (this.urlDirect) {
            this._router.navigate([`app/admin/oid/detail/${data.idOid}`], { queryParams: { routeReturn: this.urlParams } });
         } else {
            this._router.navigate([`app/admin/oid/detail/${data.idOid}`], {
               queryParams: { ...this.urlParams },
            });
         }
         this.activeModal.close();
      }
   }
   loadTable(data?: any[]) {
     // console.log(data);
     //console.log('Va a retornar orivate');
      this.modelTable = new fromModels.ModelTable({
         columns: this.returnColumns(this.oidFlag),
         rows: data,
         filter: true,
         optionsNumber: [5, 10, 20],
         columnsActions: ['oid'],
         optionsSelect: this.returnOptionsSelect(this.oidFlag),
         title: { class: '', text: 'Lista valores', textMessage: 'Estos son los valores permitidos para el dato '+ this.dataElement  },
         messageEmpty: { message: 'No hay elementos de datos asociados.' },
         tblGeneral: true,
         border: false,
         borderContentTbl: true,
         titlePadding: true,
         modalPadding: true,
      });
    //  console.log(this.modelTable);
      
   }
   loadTablePublic(data?: any[]) {
     // console.log('Va a retornar public');
      
      this.modelTable = new fromModels.ModelTable({
         columns: this.returnPublicColumns(this.oidFlag),
         
         rows: data,
         filter: true,
         optionsNumber: [5, 10, 20],
         columnsActions: ['oid'],
         optionsSelect: this.returnOptionsSelect(this.oidFlag),
         title: { class: '', text: 'Lista valores', textMessage: 'Estos son los valores permitidos para el dato '+ this.dataElement },
         messageEmpty: { message: 'No hay elementos de datos asociados.' },
         tblGeneral: true,
         border: false,
         borderContentTbl: true,
         titlePadding: true,
         modalPadding: true,
      });
   }
   returnColumns(oidFlag: boolean) {
      const columns: fromModels.columnsTable[] = [
         {
            prop: 'nombre',
            name: 'Nombre',
            minWidth: 80,
            maxWidth: 100,
         },
         {
            prop: 'codigo',
            name: 'Código',
            minWidth: 80,
            maxWidth: 100,
         },
      ];
      if (oidFlag) {
         columns.splice(2, 0, {
            prop: 'oid',
            name: 'OID',
            minWidth: 80,
            maxWidth: 100,
            oidTooltip: false
         });
      }
      return columns;
   }

   returnPublicColumns(oidFlag: boolean) {
      const columns: fromModels.columnsTable[] = [
         {
            prop: 'nombre',
            name: 'Nombre',
            minWidth: 80,
            maxWidth: 100,
         },
         {
            prop: 'codigo',
            name: 'Código',
            minWidth: 80,
            maxWidth: 100,
         },
      ];
      if (oidFlag) {
         columns.splice(2, 0, {
            prop: 'oid',
            name: 'OID',
            minWidth: 80,
            maxWidth: 100,
            oidTooltip: true
         });
      }
      return columns;
   }

   returnOptionsSelect(oidFlag: boolean) {
      const optionsSelect: fromModels.optionData[] = [
         { display: 'Nombre', value: 'nombre' },
         { display: 'Código', value: 'codigo' },
      ];
      if (oidFlag) {
         optionsSelect.splice(2, 0, {
            display: 'OID', value: 'oid'
         });
      }
      return optionsSelect;
   }
}
